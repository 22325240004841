import * as React from 'react';
import { PaymentType } from 'src/enums/Payment';
import { BusRow } from 'src/models/Bus';
import { IBusStop } from 'src/models/BusStop';
import { TransitAgencyFund } from 'src/models/Funds';
import { CommonFieldsForFeature } from 'src/models/IFeature';
import { EnabledPaymentType } from 'src/models/Payment';
import { ITransitAgency } from 'src/models/TransitAgency';
import create from 'zustand';
import shallow from 'zustand/shallow';

export interface TransitAgencyStore {
  busStops: Array<IBusStop>;
  setBusStops: (array: Array<IBusStop>) => void;
  buses: BusRow[];
  setBuses: (buses: BusRow[]) => void;
  features: CommonFieldsForFeature[] | null;
  setFeatures: (features: CommonFieldsForFeature[]) => void;
  getFeature: (featureType: string) => boolean | undefined;
  geoJson: any | null;
  setGeoJson: (geoJson: any) => void;
  transitAgencyDetails: ITransitAgency | null;
  setTransitAgencyDetails: (transitAgencyDetails: ITransitAgency) => void;
  enabledPaymentTypes: EnabledPaymentType[];
  setEnabledPaymentTypes: (enabledPaymentTypes: EnabledPaymentType[]) => void;
  getEnabledPaymentType: (paymentType: PaymentType) => boolean;
  timezoneDetails: { taTimezone: string; timezoneMismatch: boolean } | null;
  setTimezoneDetails: (timezoneDetails: { taTimezone: string; timezoneMismatch: boolean }) => void;
  funds: Array<TransitAgencyFund>;
  setFunds: (array: Array<TransitAgencyFund>) => void;
}

export const transitAgencyStore = create<TransitAgencyStore>((set, get) => ({
  busStops: [],
  setBusStops: (array) => set({ busStops: array }),
  buses: [],
  setBuses: (buses) => set({ buses }),
  features: null,
  setFeatures: (features) => set({ features }),
  getFeature: (featureType) => get().features?.some((feature) => feature.type === featureType),
  geoJson: null,
  setGeoJson: (geoJson) => set({ geoJson }),
  transitAgencyDetails: null,
  setTransitAgencyDetails: (transitAgencyDetails) => set({ transitAgencyDetails }),
  enabledPaymentTypes: [],
  setEnabledPaymentTypes: (enabledPaymentTypes) => set({ enabledPaymentTypes }),
  getEnabledPaymentType: (paymentType) =>
    get().enabledPaymentTypes?.some((type) => type.payment_type === paymentType),
  timezoneDetails: null,
  setTimezoneDetails: (timezoneDetails) => set({ timezoneDetails }),
  funds: [],
  setFunds: (array) => set({ funds: array })
}));

export const transitAgencyStoreHOC = <T extends TransitAgencyStore = TransitAgencyStore>(
  BaseComponent: React.ComponentType<T>
) => {
  return (props: Omit<T, keyof TransitAgencyStore>) => {
    const store = transitAgencyStore(
      (state) => ({
        busStops: state.busStops,
        setBusStops: state.setBusStops,
        buses: state.buses,
        setBuses: state.setBuses,
        features: state.features,
        setFeatures: state.setFeatures,
        getFeature: state.getFeature,
        geoJson: state.geoJson,
        setGeoJson: state.setGeoJson,
        transitAgencyDetails: state.transitAgencyDetails,
        setTransitAgencyDetails: state.setTransitAgencyDetails,
        enabledPaymentTypes: state.enabledPaymentTypes,
        setEnabledPaymentTypes: state.setEnabledPaymentTypes,
        getEnabledPaymentTypes: state.getEnabledPaymentType,
        timezoneDetails: state.timezoneDetails,
        setTimezoneDetails: state.setTimezoneDetails,
        funds: state.funds,
        setFunds: state.setFunds
      }),
      shallow
    );

    return <BaseComponent {...(props as T)} transitAgencyStore={store} />;
  };
};
