import { ILocationWithGeoJSON } from 'src/models/Location';
import authenticatedAxiosInstance from '../axios/axios-authorized';
import { BusStopRow, IBusStop } from 'src/models/BusStop';

export const getTransitAgencyBusStops = async (
  taId: number
): Promise<{
  formattedBusStops: IBusStop[] | undefined;
  busStops?: IBusStop[];
  geojson?: ILocationWithGeoJSON;
}> => {
  const response = await authenticatedAxiosInstance.axios.get(`/busstops/${taId}`);

  return response.data;
};

export const getDepots = async (taId: number): Promise<IBusStop[]> => {
  const response = await authenticatedAxiosInstance.axios.get(`/busstops/${taId}/depots`);

  return response.data;
};

export const editStopName = async (stopId: number, name: string): Promise<string | null> => {
  return (await authenticatedAxiosInstance.axios.put(`/busstops/${stopId}`, { name })).data
    ?.message;
};

export const getBusStopWithCode = async (stopCode: number) => {
  return (await authenticatedAxiosInstance.axios.get(`/bus-stop/${stopCode}/code`))?.data;
};

export const validateBusStopChanges = async (
  reqBody: { lon: number; lat: number; busStopCode: number; stopId?: number; deactivate?: boolean },
  transitAgencyId: number
) => {
  const response = await authenticatedAxiosInstance.axios.post(
    `/bus-stop/${transitAgencyId}/validate`,
    reqBody
  );

  return response?.data;
};

export const sendBulkStopChanges = async (
  reqBody: { creates: Partial<BusStopRow>[]; updates: Partial<BusStopRow>[] },
  transitAgencyId: number
) => {
  const response = await authenticatedAxiosInstance.axios.post(
    `/bus-stop/${transitAgencyId}/bulk`,
    reqBody
  );

  return response?.data;
};
