import * as React from 'react';
import { DriverShiftForReboot } from 'src/models/DriverShifts';
import create from 'zustand';
import shallow from 'zustand/shallow';

export type DriverShiftStore = {
  driverShiftsAreEndingSoon: boolean;
  noShiftsAfterDate: Date | null;
  driverShiftsForReboot: DriverShiftForReboot[];
  setDriverShiftsAreEndingSoon: (value: boolean) => void;
  setNoShiftsAfterDate: (date: Date) => void;
  setDriverShiftsForReboot: (driverShiftsForReboot: DriverShiftForReboot[]) => void;
};

export const driverShiftStore = create<DriverShiftStore>((set) => ({
  driverShiftsAreEndingSoon: false,
  noShiftsAfterDate: null,
  driverShiftsForReboot: [],
  setDriverShiftsAreEndingSoon: (value) => set({ driverShiftsAreEndingSoon: value }),
  setNoShiftsAfterDate: (date) => set({ noShiftsAfterDate: date }),
  setDriverShiftsForReboot: (driverShiftsForReboot) => set({ driverShiftsForReboot })
}));

export const driverShiftHOC = <T extends DriverShiftStore = DriverShiftStore>(
  BaseComponent: React.ComponentType<T>
) => {
  return (props: Omit<T, keyof DriverShiftStore>) => {
    const store = driverShiftStore(
      (state) => ({
        driverShiftsAreEndingSoon: state.driverShiftsAreEndingSoon,
        noShiftsAfterDate: state.noShiftsAfterDate,
        driverShiftsForReboot: state.driverShiftsForReboot,
        setDriverShiftsAreEndingSoon: state.setDriverShiftsAreEndingSoon,
        setNoShiftsAfterDate: state.setNoShiftsAfterDate,
        setDriverShiftsForReboot: state.setDriverShiftsForReboot
      }),
      shallow
    );

    return <BaseComponent {...(props as T)} driverShiftStore={store} />;
  };
};
