import { TFunction } from 'i18next';
import { getPassengerFunds, validatePassengerFundsForTrip } from 'src/api/funds';
import { MessageStatus } from 'src/enums/Snackbar';
import {
  FormattedFundForPayment,
  FundVerification,
  PassengerFund,
  TransitAgencyFund
} from 'src/models/Funds';
import { ITripRequestOrganizationBody } from 'src/models/Organization';

const DEFAULT_FUND_COVERAGE = 0.5;

export const getTotalFundsAmountInCents = (funds: FormattedFundForPayment[]) => {
  if (!funds?.length) return 0;

  const total = funds.reduce((acc, obj) => acc + Number(obj.fundCost), 0) ?? 0;
  const roundedTotal = Math.round(total * 100);

  return roundedTotal;
};

export const updateFundsInStore = (funds: Array<TransitAgencyFund>) => {
  const formattedFunds = funds.map((fund) => {
    const tenPercent = fund.fundTotal * 0.1;
    const isLowInFunds = fund.fundBalance < tenPercent && fund.isActiveForTransitAgency;

    return {
      fundId: fund.fundId,
      fundName: fund.fundName,
      priority: fund.priority,
      isActiveForTransitAgency: fund.isActiveForTransitAgency,
      showWarning: isLowInFunds,
      fundEndDate: fund.fundEndDate,
      fundStartDate: fund.fundStartDate,
      fundTotal: fund.fundTotal,
      fundBalance: fund.fundBalance
    };
  });

  return formattedFunds;
};

export const getFundCoverageForTrip = (
  fund: PassengerFund,
  price: number,
  subsidyReduction: number,
  subsidyFunds: FormattedFundForPayment[],
  showSnackbar: (message: string, status: string | MessageStatus) => void,
  t: TFunction
) => {
  const amountLeftAfterReduction = price - subsidyReduction;

  const hasInsuffientFunds =
    fund.fundBalance < amountLeftAfterReduction ||
    (fund.spendingAmount !== null && fund.spendingAmount == 0);

  if (hasInsuffientFunds) {
    showSnackbar(t('insufficient_funds'), MessageStatus.ERROR);
  }

  const tripCoveredInPercentage = subsidyFunds.reduce(
    (acc: number, obj: FormattedFundForPayment) => acc + Number(obj.fundCoverage),
    0
  );

  // default new fund coverage to 50% unless the trip is already covered by 50% or more
  if (tripCoveredInPercentage <= DEFAULT_FUND_COVERAGE) {
    // if the fund is covering the last 50% of the trip price, use amountLeftAfterReduction to avoid errors with decimals when price is an odd number
    // eg. trip is 4.33, the first 50% is 2.16 and the second should be 2.17 (and not 2.16 after formatting to 2 decimal places)

    const amount =
      tripCoveredInPercentage === DEFAULT_FUND_COVERAGE
        ? amountLeftAfterReduction
        : // : parseInt(priceObject?.price * DEFAULT_FUND_COVERAGE);
          price * DEFAULT_FUND_COVERAGE;
    return { coverage: DEFAULT_FUND_COVERAGE, amount };
  } else {
    const calculatedCoverage = amountLeftAfterReduction / price;
    return { coverage: calculatedCoverage, amount: amountLeftAfterReduction };
  }
};

export const getFundsForPassenger = async (
  requestDetails: {
    passengerId: string;
    transitAgencyId: number;
    subsidyFunds: FormattedFundForPayment[]; // funds already saved in request store/context
    subsidyReduction: number;
    price?: number;
  },
  showSnackbar: (message: string, status: string | MessageStatus) => void,
  t: TFunction,
  setPassengersFunds?: (funds: PassengerFund[]) => void,
  saveInitialFundToStore?: (funds: FormattedFundForPayment[]) => void
) => {
  const { passengerId, transitAgencyId, subsidyFunds, subsidyReduction } = requestDetails;

  const includeTrips = false;
  const response = await getPassengerFunds(passengerId, transitAgencyId, includeTrips);

  if (!response.length) {
    return;
  }

  // want funds that are active and have money left over
  const activeFunds = response.filter(
    (fund: PassengerFund) =>
      fund.isActiveForTransitAgency &&
      fund.isActiveForPassenger &&
      fund.fundBalance > 0 &&
      (fund.spendingAmount === null || fund.spendingAmount > 0)
  );

  if (!activeFunds?.length) return;

  // save based on TA priority
  activeFunds.sort((a: PassengerFund, b: PassengerFund) => a.priority - b.priority);

  // if there are no subsidy funds already saved but there are active funds
  if (!subsidyFunds.length && saveInitialFundToStore && requestDetails.price) {
    const coveredByFund = getFundCoverageForTrip(
      activeFunds[0],
      requestDetails.price,
      subsidyReduction,
      subsidyFunds,
      showSnackbar,
      t
    );

    const fund = {
      fundId: activeFunds[0].fundId,
      fundName: activeFunds[0].fundName,
      fundBalance: activeFunds[0].fundBalance,
      spendingAmount: activeFunds[0].spendingAmount,
      fundCoverage: coveredByFund?.coverage || null,
      fundCost: coveredByFund?.amount ? (coveredByFund.amount / 100).toFixed(2) : null
    };

    saveInitialFundToStore([{ ...fund }]);
  }

  if (setPassengersFunds) {
    setPassengersFunds(activeFunds);
  } else {
    return activeFunds;
  }
};

export const verifyFundsForPayment = async (requestDetails: {
  passengerId: string;
  transitAgencyId: number;
  subsidyFunds: FormattedFundForPayment[];
}) => {
  const { transitAgencyId, passengerId, subsidyFunds } = requestDetails;
  if (!transitAgencyId || !passengerId || !subsidyFunds?.length) {
    return;
  }

  const reqBody = subsidyFunds.map((fund: FormattedFundForPayment) => {
    const fundCost = Math.trunc(Number(fund.fundCost) * 100);

    return {
      fundId: fund.fundId,
      fundCost: fundCost,
      fundPercentage: fund.fundCoverage
    };
  });

  const response = await validatePassengerFundsForTrip(passengerId, transitAgencyId, {
    fundsArray: reqBody
  });

  let errorMessage = 'error_generic';

  const allFundsValidated = response?.every((fund: FundVerification) => {
    if (fund.passengerFundIsDepleted || fund.transitAgencyFundIsDepleted) {
      errorMessage = 'errors:depleted_fund';
    }

    if (!fund.passengerFundIsActive || !fund.transitAgencyFundIsActive) {
      errorMessage = 'errors:expired_fund';
    }

    return (
      fund.passengerFundIsActive &&
      fund.transitAgencyFundIsActive &&
      !fund.passengerFundIsDepleted &&
      !fund.transitAgencyFundIsDepleted
    );
  });

  if (allFundsValidated) {
    return { validated: true, message: '' };
  } else {
    return { validated: false, message: errorMessage };
  }
};

export const verifyFundAndOrganizationReduction = (
  price: string | number,
  funds: FormattedFundForPayment[],
  organizations: ITripRequestOrganizationBody[]
) => {
  const totalFundsAmountInDollars = funds.reduce((acc, fund) => acc + Number(fund.fundCost), 0);

  const totalOrganizationsAmount = organizations.reduce(
    (acc, organization) => acc + Number(organization.amount),
    0
  );

  return totalFundsAmountInDollars + totalOrganizationsAmount <= Number(price);
};
