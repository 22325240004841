import * as React from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';

export interface CurrentUser {
  transit_agency_user_id: string;
  transit_angency_user_name: string;
  email: string;
  first_name: string;
  last_name: string;
  creation_date: string;
  employee_identifier?: string;
  transit_agency_id: number;
  transit_agency: {
    transit_agency_name: string;
    stripe_account: string;
  };
  unit_system: string;
}

export interface CurrentUserStore {
  currentUser: CurrentUser | null;
  setCurrentUser: (userInfo: CurrentUser) => void;
}

export const currentUserStore = create<CurrentUserStore>((set) => ({
  currentUser: null,
  setCurrentUser: (userInfo) => set({ currentUser: userInfo })
}));

export const currentUserHOC = <T extends CurrentUserStore = CurrentUserStore>(
  BaseComponent: React.ComponentType<T>
) => {
  return (props: Omit<T, keyof CurrentUserStore>) => {
    const store = currentUserStore(
      (state) => ({
        currentUser: state.currentUser,
        setCurrentUser: state.setCurrentUser
      }),
      shallow
    );

    return <BaseComponent {...(props as T)} currentUserStore={store} />;
  };
};
