/**
 * Checks if the user has the specified permission string
 * @param {Object} auth0 The auth0 object obtained from the HOC withAuth0
 * @param {String} permissionString The permission string to check. eg: "manage:users"
 * @returns
 */
export const checkPermissions = (user, permissionString) => {
  if (user) {
    const permissions = user['http://blaisetransitagency.com/permissions'];

    if (permissions?.includes(permissionString)) {
      return true;
    }
  }
  return false;
};

// Allows storing auth0's getAccessTokenSilently, so it can be used outside of a React component (Zustand auth store).
let getAccessTokenSilently = null;
export const accessTokenContainer = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func) => (getAccessTokenSilently = func)
};
