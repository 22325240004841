import React, { createContext, useContext, useMemo, useState } from 'react';
import { Snackbar } from 'src/components/Snackbar/Snackbar';
import { MessageStatus } from 'src/enums/Snackbar';

type snackBarContext = {
  showSnackbar: (message: string, status: string | MessageStatus) => void;
};

const initialSnackbarDetailsState = {
  isOpen: false,
  message: '',
  status: ''
};

export const SnackbarContext = createContext<snackBarContext | null>(null);

// snackbar provider and context that is accessible throughout the app
const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const [snackbarDetails, setSnackbarDetails] = useState(initialSnackbarDetailsState);

  const showSnackbar = (message: string, status: string | MessageStatus) => {
    setSnackbarDetails({ message, status, isOpen: true });
  };

  const closeSnackbar = () => {
    setSnackbarDetails(initialSnackbarDetailsState);
  };

  const contextValue = useMemo(
    () => ({
      showSnackbar
    }),
    [showSnackbar]
  );

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar
        isOpen={snackbarDetails.isOpen}
        onClose={closeSnackbar}
        message={snackbarDetails.message}
        status={snackbarDetails.status}
      />
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error('useSnackbarContext must be used within SnackbarProvider');
  }
  return context;
};
