import { getDriverShiftsForAlgoReboot } from 'src/api/driverShifts';
import { driverShiftStore } from 'src/store/driverShift';
import { transitAgencyStore } from 'src/store/transitAgency';
import moment from 'moment-timezone';

// check if any driver shifts need to be rebooted for today's shift
const getDriverShiftsForReboots = async () => {
  const storedAgency = transitAgencyStore.getState().transitAgencyDetails;
  if (!storedAgency) return;

  const {
    operating_hours_start: operatingHoursStart,
    operating_hours_end: operatingHoursEnd,
    timezone
  } = storedAgency;

  if (!operatingHoursStart || !operatingHoursEnd || !timezone) return;

  const todayStart = moment().tz(timezone);
  const todayEnd = moment().tz(timezone);

  const [hoursStart, minutesStart, secondsStart] = operatingHoursStart.split(':').map(Number);
  todayStart.hours(hoursStart).minutes(minutesStart).seconds(secondsStart);
  const todayStartInUTC = todayStart.toISOString();

  const [hoursEnd, minutesEnd, secondsEnd] = operatingHoursEnd.split(':').map(Number);
  const operatingHoursEndNextDay = hoursEnd > 0 && hoursEnd < hoursStart;
  if (operatingHoursEndNextDay) {
    todayEnd.add(1, 'days');
  }
  todayEnd.hours(hoursEnd).minutes(minutesEnd).seconds(secondsEnd);
  const todayEndInUTC = todayEnd.toISOString();

  const shifts = (await getDriverShiftsForAlgoReboot(todayStartInUTC, todayEndInUTC)) ?? [];

  driverShiftStore.setState({ driverShiftsForReboot: shifts });
};

export default getDriverShiftsForReboots;
